
<template>
  <div>
    <CCard>
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="NIP - Nama">
                  <div role="group" class="form-group">
                    <label class>NIP - Nama</label>
                    <v-select
                      v-model="form.nip"
                      :options="optionsPgw"
                      label="nama"
                      :reduce="x => x.nip"
                      :filterable="false"
                      @search="onSearch"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <div role="group" class="form-group">
                  <label class>Tanggal</label>
                  <datepicker
                    placeholder="Tanggal"
                    input-class="datePicker is-valid"
                    v-model="form.tgl"
                    :bootstrap-styling="true"
                  ></datepicker>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput label="Tempat" placeholder="Tempat" v-model="form.tempat" :isValid="true"></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CTextarea v-model="form.keterangan" label="Keterangan" rows="9" :isValid="true" />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInputFile
                  label="File input"
                  @change="uploadFile"
                  :isValid="true"
                  custom
                  v-if="!form.upload_dokumen"
                />
                <CSpinner color="success" size="sm" v-if="loadingFile" />
                <CButtonGroup size="sm" v-if="form.upload_dokumen">
                  <CButton
                    color="success"
                    class="px-4"
                    @click="downloadFile"
                  >Download File Uji Kompetensi</CButton>
                  <CButton color="danger" class="px-4" @click="deleteFile">Hapus File</CButton>
                </CButtonGroup>
                <hr />
              </CCol>
            </CRow>

            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
import VSelect from "vue-select";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
export default {
  name: "FormUjiKompentensi",
  props: ["item"],
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
    Datepicker,
  },
  data() {
    return {
      errorMessage: null,
      form: {
        nip: null,
        tgl: null,
        tempat: null,
        keterangan: null,
        upload_dokumen: null,
      },
      loading: false,
      loadingFile: false,
      optionsPgw: [],
    };
  },
  watch: {
    item: function (item) {
      if (item) {
        this.form.nip = item.nip;
        this.form.tgl = item.tgl;
        this.form.tempat = item.tempat;
        this.form.keterangan = item.keterangan;
        this.form.upload_dokumen = item.upload_dokumen;
        const array3 = [];
        array3.push({
          nip: item.d_identitas.nip,
          nama: item.d_identitas.nip + " - " + item.d_identitas.nama,
        });
        this.optionsPgw = array3;
      }
    },
  },
  methods: {
    loadSelect() {
      // this.search(this.form.nip, this);
    },
    async onSearch(search, loading) {
      loading(true);
      await this.search(search, this);
      loading(false);
    },
    search: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "d_identitas/autocomplete",
        search
      );
      if (search) vm.optionsPgw = options;
    }, 300),
    async uploadFile(files) {
      const file = new FormData();
      file.append("files", files[0]);
      try {
        this.loadingFile = true;
        let { data } = await axios.post("/api/upload", file);
        this.form.upload_dokumen = data.url;
      } catch (error) {
        console.log(error);
        this.form.upload_dokumen = null;
      } finally {
        this.loadingFile = false;
      }
    },
    downloadFile() {
      window.open(this.form.upload_dokumen, "_blank");
    },
    deleteFile() {
      this.form.upload_dokumen = null;
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("uji_kompetensi/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch("uji_kompetensi/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.nip = null;
              vm.form.tgl = null;
              vm.form.tempat = null;
              vm.form.keterangan = null;
              vm.form.upload_dokumen = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
