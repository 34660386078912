// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

// master_golongan_ruang.js
export const LOAD_OPTION_COMPELTE = 'LOAD_OPTION_COMPELTE'
export const LOAD_OPTION_FAIL = 'LOAD_OPTION_FAIL'

// master_golongan_ruang.js
export const LOAD_OPTION_ESELON_COMPELTE = 'LOAD_OPTION_ESELON_COMPELTE'
export const LOAD_OPTION_ESELON_FAIL = 'LOAD_OPTION_ESELON_FAIL'
