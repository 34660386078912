
<template>
  <div>
    <CCard>
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3" v-if="item">
                <CInput label="Kode Unit Kerja" placeholder readonly v-model="form.id_unit_kerja"></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Instansi">
                  <div role="group" class="form-group">
                    <label class>Instansi</label>
                    <v-select
                      v-model="form.id_instansi"
                      :options="optionsInstansi"
                      label="nama_instansi"
                      :reduce="instansi => instansi.id_instansi"
                      :filterable="false"
                      @search="onSearch"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                  <div role="group" class="form-group">
                    <label class>Unit Kerja Induk</label>
                    <v-select
                      v-model="form.parentid"
                      :options="optionsUnitKerja"
                      label="nama_unit_kerja"
                      :reduce="unit_kerja => unit_kerja.id_unit_kerja"
                      :filterable="false"
                      @search="onSearchUnitKerja"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Nama Unit Kerja">
                  <CInput
                    label="Nama Unit Kerja"
                    placeholder="Nama Unit Kerja"
                    v-model="form.nama_unit_kerja"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Nama Jabatan">
                  <CInput
                    label="Nama Jabatan"
                    placeholder="Nama Jabatan"
                    v-model="form.jabatan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Eselon">
                  <div role="group" class="form-group">
                    <label class>Eselon</label>
                    <v-select
                      v-model="form.id_eselon"
                      :options="optionsEselon"
                      label="nama_eselon"
                      :reduce="eselon => eselon.id_eselon"
                      :filterable="true"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <CInput label="TUnit" placeholder="TUnit" v-model="form.tunit"></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CTextarea v-model="form.ikhtisar_jabatan" label="Iktisar Jabatan" rows="9" />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <validation-provider rules="numeric" v-slot="{ errors }" name="Kelas Jabatan">
                  <CInput
                    label="Kelas Jabatan"
                    placeholder="Kelas Jabatan"
                    v-model="form.kelas_jabatan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="numeric" v-slot="{ errors }" name="ABK">
                  <CInput
                    label="ABK"
                    placeholder="ABK"
                    v-model="form.abk"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <CInput label="Keterangan" placeholder="Keterangan" v-model="form.ket"></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
import VSelect from "vue-select";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "FormInformasiUmum",
  props: ["item"],
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  computed: {
    ...mapGetters("master_eselon", {
      optionsEselon: "options",
    }),
  },
  data() {
    return {
      errorMessage: null,
      form: {
        id_instansi: null,
        parentid: null,
        id_unit_kerja: null,
        nama_unit_kerja: null,
        jabatan: null,
        id_eselon: null,
        ikhtisar_jabatan: null,
        tunit: null,
        kelas_jabatan: null,
        abk: null,
        ket: null,
      },
      loading: false,
      optionsUnitKerja: [],
      optionsInstansi: [],
    };
  },
  watch: {
    "form.id_instansi": function () {
      this.searchUnitKerja("", this);
    },
    item: function (item) {
      if (item) {
        const array = [];
        array.push(item.mst_instansi);
        this.optionsInstansi = array;
        if (item.mst_unit_kerja) {
          const array2 = [];
          array2.push(item.mst_unit_kerja);
          this.optionsUnitKerja = array2;
        }
        this.form.id_instansi = item.id_instansi.toString();
        this.form.parentid = item.parentid;
        this.form.id_unit_kerja = item.id_unit_kerja;
        this.form.nama_unit_kerja = item.nama_unit_kerja;
        this.form.jabatan = item.jabatan;
        this.form.id_eselon = item.id_eselon;
        this.form.tunit = item.tunit;
        this.form.ikhtisar_jabatan = item.ikhtisar_jabatan;
        this.form.kelas_jabatan = item.kelas_jabatan;
        this.form.abk = item.abk;
        this.form.ket = item.ket;
      }
    },
  },
  methods: {
    loadSelect() {
      this.search("", this);
    },
    async onSearch(search, loading) {
      loading(true);
      await this.search(search, this);
      loading(false);
    },
    search: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      vm.optionsInstansi = options;
    }, 500),
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
        search: search,
        id_instansi: vm.form.id_instansi,
        parent: '',
      });
      vm.optionsUnitKerja = options;
    }, 500),
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("jabatan_stuktural_informasi/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch(
              "jabatan_stuktural_informasi/store",
              this.form
            );
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.id_instansi = null;
              vm.form.parentid = null;
              vm.form.id_unit_kerja = null;
              vm.form.nama_unit_kerja = null;
              vm.form.jabatan = null;
              vm.form.id_eselon = null;
              vm.form.tunit = null;
              vm.form.ikhtisar_jabatan = null;
              vm.form.kelas_jabatan = null;
              vm.form.abk = null;
              vm.form.ket = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
