var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Jenis Layanan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Jenis Layanan")]),_c('v-select',{class:[
                          { 'is-valid': !errors[0] },
                          { 'is-invalid': errors[0] } ],attrs:{"options":_vm.optionsJenisLayanan,"label":"nama_layanan","reduce":function (jenis_layanan) { return jenis_layanan.id; },"filterable":false},model:{value:(_vm.form.ref_jenis_layanan_id),callback:function ($$v) {_vm.$set(_vm.form, "ref_jenis_layanan_id", $$v)},expression:"form.ref_jenis_layanan_id"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nama Dokumen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Nama Dokumen","placeholder":"Nama Dokumen","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.nama_dokumen),callback:function ($$v) {_vm.$set(_vm.form, "nama_dokumen", $$v)},expression:"form.nama_dokumen"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Format File"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Format File","placeholder":"Format File","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.format_file),callback:function ($$v) {_vm.$set(_vm.form, "format_file", $$v)},expression:"form.format_file"}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }