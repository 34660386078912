var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nama Waktu Kerja"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Nama Waktu Kerja","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.time_name),callback:function ($$v) {_vm.$set(_vm.form, "time_name", $$v)},expression:"form.time_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Jam Masuk"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Jam Masuk")]),_c('datetime',{attrs:{"format":"H:i","name":"start_time","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.start_time),callback:function ($$v) {_vm.$set(_vm.form, "start_time", $$v)},expression:"form.start_time"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Jam Pulang"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Jam Pulang")]),_c('datetime',{attrs:{"format":"H:i","name":"end_time","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.end_time),callback:function ($$v) {_vm.$set(_vm.form, "end_time", $$v)},expression:"form.end_time"}})],1)]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }