import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async destroy(_ctx, payload) {
        try {
            await axios.delete(`${payload.url}/${payload.id}`)
        } catch (error) {
            return []
        }
    },
    async update(_ctx, payload) {
        try {
            let data = await axios.patch(`${payload.url}/${payload.id}`, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async store(_ctx, payload) {
        try {
            let data = await axios.post(payload.url, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async index(_ctx, payload) {
        const { data } = await axios.get(`${payload.url}?page=${payload.page}`)
        return data
    },
    async indexLeave(_ctx, payload) {
        const { data } = await axios.get(`${payload.url}?page=${payload.page}&pegawai=${payload.data.pegawai}&approval=${payload.data.selectedApproval}`)
        return data
    },
    async findById(_ctx, payload) {
        const { data } = await axios.get(`${payload.url}/${payload.id}$`)
        return data
    }
}