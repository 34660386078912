
<template>
  <div>
    <CCard>
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="No. Surat">
                  <CInput
                    label="No. Surat"
                    placeholder="No. Surat"
                    v-model="form.no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="Tanggal Surat">
                  <div role="group" class="form-group">
                    <label class>Tanggal Surat</label>
                    <datepicker
                      placeholder="Tanggal Surat"
                      v-model="form.tgl_surat"
                      :bootstrap-styling="true"
                      :input-class="'datePicker'"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></datepicker>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Instansi">
                  <div role="group" class="form-group">
                    <label class>Instansi</label>
                    <v-select
                      v-model="form.id_instansi"
                      :options="optionsInstansi"
                      label="nama_instansi"
                      :reduce="instansi => instansi.id_instansi"
                      :filterable="false"
                      @search="onSearchInstansi"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                  <div role="group" class="form-group">
                    <label class>Unit Kerja</label>
                    <v-select
                      v-model="form.id_unit_kerja"
                      :options="optionsUnitKerja"
                      label="nama_unit_kerja"
                      :reduce="unit_kerja => unit_kerja.id_unit_kerja"
                      :filterable="false"
                      @search="onSearchUnitKerja"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                  <CButton class="col-md-2" color="info" @click="addDetails">
                    <CIcon name="cil-plus" />Tambah Pegawai
                  </CButton>
                </CButtonGroup>
              </CCol>
            </CRow>
            <hr />
            <template v-for="(data, index) in form.details">
              <fieldset class="form-group border p-3" :key="index">
                <div small style :key="componentKey">
                  <CRow>
                    <CCol>
                      <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                        <CButton class="col-md-1" color="danger" @click="deleteDetails(index)">Hapus</CButton>
                      </CButtonGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <validation-provider rules="required" v-slot="{ errors }" name="NIP - Nama">
                        <div role="group" class="form-group">
                          <label class>NIP - Nama</label>
                          <v-select
                            v-model="data.nip"
                            :options="data.optionsPgw"
                            label="nama"
                            :reduce="x => x.nip"
                            :filterable="false"
                            @search="onSearch(index, $event)"
                            :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <validation-provider rules="required" v-slot="{ errors }" name="NIP - Nama">
                        <div role="group" class="form-group">
                          <label class>Jenis Kenaikan Pangkat</label>
                          <v-select
                            v-model="data.jenis_pelayanan_array"
                            :options="optionsJenisLayanan"
                            label="nama_layanan"
                            :filterable="true"
                            @input="onChangesJenisLayanan(index, $event)"
                            :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <!---->
                  <fieldset class="form-group border p-3" v-if="data.informasi_pegawai">
                    <legend class="w-auto px-2">Informasi Pegawai</legend>

                    <CRow>
                      <CCol md="6">
                        <CInput
                          label="Jenis Kelamin"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.jenis_kelamin"
                        ></CInput>
                        <CInput
                          label="Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_jabatan"
                        ></CInput>
                        <CInput
                          label="Nama Unit Kerja"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_unit_kerja"
                        ></CInput>
                        <CInput
                          label="Golongan Ruang"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.pangkat_golongan_ruang"
                        ></CInput>
                      </CCol>
                      <CCol md="6">
                        <CInput
                          label="Gaji Pokok"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.gaji_pokok"
                        ></CInput>
                        <CInput
                          label="TMT Pangkat"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.tmt_pangkat"
                        ></CInput>
                        <CInput
                          label="Masa Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.masa_jabatan"
                        ></CInput>
                        <CInput
                          label="Umur"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.umur"
                        ></CInput>
                      </CCol>
                    </CRow>
                  </fieldset>
                  <div class="position-relative table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th class style="vertical-align: middle; overflow: hidden;">
                            <div>No</div>
                            <!---->
                          </th>
                          <th class style="vertical-align: middle; overflow: hidden;">
                            <div>Jenis Dokumen</div>
                            <!---->
                          </th>
                          <th class style="vertical-align: middle; overflow: hidden;">
                            <div>Format File</div>
                            <!---->
                          </th>
                          <th class style="vertical-align: middle; overflow: hidden;">
                            <div>File Upload</div>
                            <!---->
                          </th>
                        </tr>
                        <!---->
                      </thead>
                      <tbody class="position-relative">
                        <tr v-for="(dataUpload, index2) in data.upload_detail" :key="index2">
                          <td>{{index2+1}}</td>
                          <td>{{dataUpload.nama_dokumen}}</td>
                          <td>{{dataUpload.format_file}}</td>
                          <td style="width:20%">
                            <CInputFile
                              label="File input"
                              @change="uploadFile($event, index, index2)"
                              :isValid="true"
                              custom
                              v-if="!dataUpload.upload_dokumen && !dataUpload.loadingFile"
                            />
                            <CSpinner color="success" size="sm" v-if="dataUpload.loadingFile" />
                            <CButtonGroup size="sm" v-if="dataUpload.upload_dokumen">
                              <CButton
                                color="success"
                                class="px-4"
                                @click="downloadFile(index, index2)"
                              >Download File</CButton>
                              <CButton
                                color="danger"
                                class="px-4"
                                @click="deleteFile(index, index2)"
                              >Hapus File</CButton>
                            </CButtonGroup>
                          </td>
                        </tr>
                      </tbody>
                      <!---->
                    </table>
                    <!---->
                  </div>
                  <!---->
                </div>
              </fieldset>
            </template>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
              <CCol col="6" class="text-right">
                <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                <CButton color="link" class="d-lg-none">Register now!</CButton>-->
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import VSelect from "vue-select";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import axios from "axios";

import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "FormUsulanKenaikanPangkat",
  components: {
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Datepicker,
  },
  data() {
    return {
      errorMessage: null,
      optionsPgw: [],
      optionsInstansi: [],
      optionsUnitKerja: [],
      optionsJenisLayanan: [],
      form: {
        id_instansi: null,
        id_unit_kerja: null,
        no_surat: null,
        tgl_surat: null,
        details: [],
      },
      loading: false,
      item: null,
      componentKey: 0,
    };
  },
  computed: {},
  mounted() {
    this.searchLayanan("", this);
  },
  watch: {
    item: function (item) {
      if (item) {
        this.form.id_instansi = item.id_instansi.toString();
        this.form.id_unit_kerja = item.id_unit_kerja;
        this.form.no_surat = item.no_surat;
        this.form.tgl_surat = item.tgl_surat;
        const array = [];
        array.push(item.mst_instansi);
        this.optionsInstansi = array;
        const array2 = [];
        array2.push(item.mst_unit_kerja);
        this.optionsUnitKerja = array2;

        this.form.details = item.pelayanan_detail;
        this.form.details.forEach((element) => {
          const array3 = [];
          array3.push(element.d_identitas);
          element.optionsPgw = array3;
          element.jenis_pelayanan_array = element.ref_jenis_pelayanan;
          element.upload_detail = element.pelayanan_detail_upload;
          element.informasi_pegawai =
            element.d_identitas.v_detail_informasi_pegawai;
        });
      } else {
        this.form.id_instansi = null;
        this.form.id_unit_kerja = null;
        this.form.no_surat = null;
        this.form.tgl_surat = null;
        this.optionsInstansi = [];
        this.optionsUnitKerja = [];
        this.form.details = [];
      }
    },
  },
  methods: {
    async uploadFile(files, index, index2) {
      console.log(this.form.details[index]["upload_detail"]);
      const file = new FormData();
      file.append("files", files[0]);
      try {
        this.form.details[index]["upload_detail"][index2]["loadingFile"] = true;
        this.componentKey += 1;
        let { data } = await axios.post("/api/upload", file);
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          data.url;
      } catch (error) {
        console.log(error);
        this.form.details[index]["upload_detail"][index2][
          "upload_dokumen"
        ] = null;
      } finally {
        this.form.details[index]["upload_detail"][index2][
          "loadingFile"
        ] = false;
        this.componentKey += 1;
      }
    },
    downloadFile(index, index2) {
      window.open(
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"],
        "_blank"
      );
    },
    deleteFile(index, index2) {
      this.form.details[index]["upload_detail"][index2][
        "upload_dokumen"
      ] = null;
    },
    async onSearch(index, search) {
      // loading(true);
      await this.search(index, search, this);
      // loading(false);
    },
    search: _.debounce(async (index, search, vm) => {
      let options = await vm.$store.dispatch(
        "d_identitas/autocomplete",
        search
      );
      if (search) {
        vm.form.details[index]["optionsPgw"] = options;
      }
    }, 300),
    async searchLayanan(search) {
      let options = await this.$store.dispatch(
        "jenis_layanan/autocomplete",
        search
      );
      this.optionsJenisLayanan = options;
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("pelayanan/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch("pelayanan/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
    showModalFormPilih(item) {
      this.$refs.formPilih.toggle(item, this.item);
    },
    populateInput(item) {
      this.item = item;
    },
    async onSearchInstansi(search, loading) {
      loading(true);
      await this.searchInstansi(search, this);
      loading(false);
    },
    searchInstansi: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      if (search) vm.optionsInstansi = options;
    }, 300),
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
        search: search,
        id_instansi: vm.form.id_instansi,
        parent: "",
      });
      if (search) vm.optionsUnitKerja = options;
    }, 300),
    addDetails() {
      this.form.details.push({
        nip: null,
        ref_jenis_layanan_id: null,
        ref_persyaratan_pelayanan_id: null,
        upload_file: null,
        jenis_pelayanan_array: [],
        optionsPgw: [],
        informasi_pegawai: [],
      });
    },
    deleteDetails(index) {
      this.form.details.splice(index, 1);
    },
    onChangesJenisLayanan(index, value) {
      this.form.details[index].ref_jenis_layanan_id = value.id;
      const me = this;
      console.log(value.ref_persyaratan_pelayanan);
      var parsedobj = JSON.parse(
        JSON.stringify(value.ref_persyaratan_pelayanan)
      );
      console.log(parsedobj);

      me.form.details[index]["upload_detail"] = [];
      value.ref_persyaratan_pelayanan.forEach((element) => {
        me.form.details[index]["upload_detail"].push({
          upload_dokumen: null,
          ref_persyaratan_pelayanan_id: element.id,
          nama_dokumen: element.nama_dokumen,
          format_file: element.format_file,
          loadingFile: false,
        });
      });
    },
  },
};
</script>