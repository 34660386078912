<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}
      <a href="https://bkpsdm.purwakartakab.go.id/" target="_blank">BKPSDM Kabupaten Purwakarta</a></span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://nusatekno.co.id" target="_blank">PT. Nusatekno Global</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
