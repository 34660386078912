<template>
  <CChartBar
    :datasets="defaultDatasets"
    :labels="['Presensi Masuk', 'Presensi Keluar', 'Terlambat', 'Cuti', 'Tanpa Keterangan']"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import axios from 'axios';

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  data() {
    return {
      totalRataRata: {}
    }
  },
  async mounted() {
    let dataRataRata = await axios.get('api/pegawai/total-rata-rata-chart');

    if (dataRataRata) {
      // dataRataRata.data.map((item) => {
      //   this.totalRataRata.push(item)
      // });
      this.totalRataRata = dataRataRata.data
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          label: 'Periode Mei 2021',
          backgroundColor: ["#064420", "#185adb", "#cf0000", "#fb9300", "#f7fd04", "#f86c6b"],
          data: [this.totalRataRata.presensi_masuk, this.totalRataRata.presensi_keluar, this.totalRataRata.terlambat, this.totalRataRata.cuti, 30]
        }
      ]
    }
  }
}
</script>
