
<template>
  <div>
    <CCard>
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Nama Layanan">
                  <CInput
                    label="Nama Layanan"
                    placeholder="Nama Layanan"
                    v-model="form.nama_layanan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
export default {
  name: "FormAbkUmum",
  props: ["item"],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorMessage: null,
      form: {
        nama_layanan: null,
      },
      loading: false,
    };
  },
  watch: {
    item: function (item) {
      if (item) {
        this.form.nama_layanan = item.nama_layanan;
      }
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("jenis_layanan/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch("jenis_layanan/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.nama_layanan = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
