
import axios from 'axios';
// actions
export const actions = {

    async index(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/monitoring-jabatan-fungsional?page=' + payload.page + '&nama_unit_kerja=' + payload.data.nama_unit_kerja + '&jabatan=' + payload.data.jabatan)
            return data
        } catch (error) {
            return []
        }
    },

    async existing(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/monitoring-jabatan-fungsional/existing-employee?id_unit_kerja=' + payload.id_unit_kerja + '&id_jabatan_fungsional=' + payload.id_jabatan_fungsional)
            return data
        } catch (error) {
            return []
        }
    },

    async kandidat(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/monitoring-jabatan-fungsional/kandidat?id_unit_kerja=' + payload.id_unit_kerja + '&id_jabatan=' + payload.id_jabatan_fungsional)
            return data
        } catch (error) {
            return []
        }
    },
}
