<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="days"
  />
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import daysInMonth from '../../helper/daysInMonth'
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default {
  name: 'MainChartExample',
  components: {
    CChartLine
  },
  data() {
    return {
      totalPresensiMasuk: [],
      totalPresensiKeluar: [],
      totalTerlambat: [],
      totalCuti: [],
      days: []
    }
  },
  async mounted() {
    this.days = daysInMonth(moment().month())
    let dataTotalPresensiMasuk = await axios.get('api/pegawai/total-presensi-masuk-chart');
    let dataTotalPresensiKeluar = await axios.get('api/pegawai/total-presensi-keluar-chart');
    let dataTotalTerlambat = await axios.get('api/pegawai/total-terlambat-chart');
    let dataTotalCuti = await axios.get('api/pegawai/total-cuti-chart');

    if (dataTotalPresensiMasuk) {
      dataTotalPresensiMasuk.data.map((item) => {
        this.totalPresensiMasuk.push(item)
      });
    }

    if (dataTotalPresensiKeluar) {
      dataTotalPresensiKeluar.data.map((item) => {
        this.totalPresensiKeluar.push(item)
      });
    }
    
    if (dataTotalTerlambat) {
      dataTotalTerlambat.data.map((item) => {
        this.totalTerlambat.push(item)
      });
    }
    
    if (dataTotalCuti) {
      dataTotalCuti.data.map((item) => {
        this.totalCuti.push(item)
      });
    }
  },
  computed: {
    defaultDatasets () {
      const brandDanger = getStyle('danger') || '#f86c6b'

      let elements = this.days.length
      const data1 = []
      
      for (let i = 0; i <= elements; i++) {
        data1.push(random(1, 20))
      }

      return [
        {
          label: 'Presensi Masuk',
          backgroundColor: '#064420',
          borderColor: "#064420",
          pointHoverBackgroundColor: "#064420",
          borderWidth: 2,
          data: this.totalPresensiMasuk,
          type: 'bar',
        },
        {
          label: 'Presensi Keluar',
          backgroundColor: '#185adb',
          borderColor: "#185adb",
          pointHoverBackgroundColor: "#185adb",
          borderWidth: 2,
          data: this.totalPresensiKeluar,
          type: 'bar',
        },
        {
          label: 'Terlambat',
          backgroundColor: 'transparent',
          borderColor: "#fb9300",
          pointHoverBackgroundColor: "#fb9300",
          borderWidth: 2,
          data: this.totalTerlambat
        },
        {
          label: 'Dinas',
          backgroundColor: 'transparent',
          borderColor: brandDanger,
          pointHoverBackgroundColor: brandDanger,
          borderWidth: 2,
          data: this.totalTerlambat
        },
        {
          label: 'Cuti',
          backgroundColor: 'transparent',
          borderColor: "#f7fd04",
          pointHoverBackgroundColor: "#f7fd04",
          borderWidth: 2,
          data: this.totalCuti
        },
        {
          label: 'Tanpa Keterangan',
          backgroundColor: 'transparent',
          borderColor: brandDanger,
          pointHoverBackgroundColor: brandDanger,
          borderWidth: 2,
          data: data1
        }
      ]
    },
    defaultOptions () {
      return {

        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          // yAxes: [{
          //   ticks: {
          //     beginAtZero: true,
          //     maxTicksLimit: 5,
          //     stepSize: Math.ceil(100 / 5),
          //     max: 100
          //   },
          //   gridLines: {
          //     display: true
          //   }
          // }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
</script>
