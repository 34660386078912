import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async destroy(_ctx, payload) {
        try {
            await axios.delete('/api/mst-jabatan-umum/' + payload)
        } catch (error) {
            return []
        }
    },
    async update(_ctx, payload) {
        try {
            let data = await axios.patch('/api/mst-jabatan-umum/' + payload.id, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/mst-jabatan-umum', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async index(_ctx, payload) {
        const { data } = await axios.get('/api/mst-jabatan-umum?page=' + payload.page + '&nama_jabatan=' + payload.data.nama_jabatan)
        return data
    }
}
