

import store from '../store'

function scrollBehavior() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("Trident"); // check if the browser is IE

    if (msie > 0) return false;

    var scrollDuration = 600
    var cosParameter = window.scrollY / 2,
        scrollCount = 0,
        oldTimestamp = performance.now();
    function step(newTimestamp) {
        scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step)
}

async function beforeEach(to, from, next) {
    if (to.name == 'Login' && store.getters['auth/check']) {
        next({ name: 'Home' })
    } else if (!store.getters['auth/check'] && to.name != 'Login') {
        next({ name: 'Login' })
    } else if (!store.getters['auth/check'] && to.name == 'Login') {
        next()
    } else {
        let user = await store.getters['auth/user'];
        let time = await store.getters['auth/time'];
        if ((time == null) || (user == null) || (time + (60 * 60 * 1000) < (Math.floor(Date.now() / 1000)))) {
            await store.dispatch("auth/fetchUser");
        }
        next();
    }
}

export { scrollBehavior, beforeEach }
