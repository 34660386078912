import moment from 'moment'

export default function daysInMonth(month) {
  var count =  moment().month(month).daysInMonth();
  
  var days = [];
  for (var i = 1; i < count+1; i++) {

    days.push(i);
  }

  return days;
}
