<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{title}}
        </slot>
      </CCardHeader>
      <CCardBody>
        <!-- <CRow v-show="!formVisibility">
          <CCol md="12">
            <CInput label="Office Time" placeholder="Office Time" v-model="form.nama_layanan"></CInput>
          </CCol>
        </CRow> -->
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="success" @click="formVisibility = !formVisibility">
            <CIcon :name="icon_button" />
            {{button_label}}
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
        </CButtonGroup>
        <hr />
        <CCollapse :show="formVisibility">
          <form-input @done="resetTabel" ref="formCreate" />
        </CCollapse>
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
          <template #show_details="{item, index}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="toggleDetails(item, index)"
              >{{Boolean(item._toggled) ? 'Tutup' : 'Edit'}}</CButton>
              <CButton
                size="sm"
                color="danger"
                variant="outline"
                square
                class="ml-1"
                @click="delete_item(item.id)"
              >Delete</CButton>
            </td>
          </template>
          <template #details="{item}">
            <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
              <!-- <form-input @done="resetTabel" :item="item" /> -->
            </CCollapse>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
// import formInput from "./Form";
import Swal from "sweetalert2";
const fields = [
  { key: "number", label: "No" },
  { key: "nip", label: "NIP" },
  { key: "start_time_desc", label: "Jam Masuk" },
  { key: "end_time_desc", label: "Jam Pulang" },
  {
    key: "show_details",
    label: "Action",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "Leave",
  components: {
    // formInput,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      formVisibility: false,
      loading: false,
      page: 1,
      totalPage: 0,
      form: {
        leave_type: null,
        leave_order: null,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    computedItems() {
      var number = 1;
      let total_pages = 10 * (this.page - 1);
      return this.items.map((item) => {
        return {
          ...item,
          number: number++ + total_pages,
        };
      });
    },
    title() {
      return this.formVisibility ? "Form Absen" : "Tabel Absen";
    },
    button_label() {
      return !this.formVisibility ? "Tambah" : "Kembali";
    },
    icon_button() {
      return !this.formVisibility ? "cil-plus" : "cil-arrow-left";
    },
  },
  methods: {
    reset() {
      this.form.nama_layanan = null;
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch("general/index", {
          url: '/api/attendance',
          page: this.page,
          data: this.form,
        });
        this.items = data.data;
        this.totalPage = data.last_page;
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    resetTabel() {
      this.formVisibility = false;
      this.loadData();
    },
    toggleDetails(item, index) {
      this.items.forEach((element) => {
        this.$set(element, "_toggled", false);
      });
      this.$set(this.items[index], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    delete_item(id) {
      Swal.fire({
        title: "Peringatan!",
        text: "Apa Anda yakin ingin menghapus data ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Hapus!",
      }).then(async (value) => {
        
        if (value.isConfirmed) {
          await this.$store.dispatch("general/destroy/", {url: 'api/attendance', id: id});
          this.loadData();
          Swal.fire({
            title: "Ok!",
            text: "Data berhasil terhapus!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          });
        }
      });
    },
  },
};
</script>
