var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._t("header",[_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.title)+" ")])],2),_c('CCardBody',[_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formVisibility),expression:"!formVisibility"}]},[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"NIP / Nama Pegawai","placeholder":"NIP / Nama Pegawai"},model:{value:(_vm.form.nip),callback:function ($$v) {_vm.$set(_vm.form, "nip", $$v)},expression:"form.nip"}})],1)],1),_c('CButtonGroup',{attrs:{"size":"sm"}},[_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formVisibility),expression:"!formVisibility"}],attrs:{"color":"info"},on:{"click":function($event){return _vm.loadData()}}},[_c('CIcon',{attrs:{"name":"cil-search"}}),_vm._v("Cari ")],1),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){_vm.formVisibility = !_vm.formVisibility}}},[_c('CIcon',{attrs:{"name":_vm.icon_button}}),_vm._v(" "+_vm._s(_vm.button_label)+" ")],1),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formVisibility),expression:"!formVisibility"}],attrs:{"color":"warning"},on:{"click":function($event){return _vm.reset()}}},[_c('CIcon',{attrs:{"name":"cil-loop-circular"}}),_vm._v("Reset ")],1)],1),_c('hr'),_c('CCollapse',{attrs:{"show":_vm.formVisibility}},[_c('form-input',{ref:"formCreate",on:{"done":_vm.resetTabel}})],1),_c('CDataTable',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formVisibility),expression:"!formVisibility"}],attrs:{"items":_vm.computedItems,"fields":_vm.fields,"hover":"","small":"","border":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"upload_dokumen",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{staticClass:"ml-1",attrs:{"color":"success","square":"","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v("Download File")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{staticClass:"ml-1",attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(_vm._s(Boolean(item._toggled) ? 'Tutup' : 'Edit'))]),_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"danger","variant":"outline","square":""},on:{"click":function($event){return _vm.delete_item(item.id)}}},[_vm._v("Delete")])],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('form-input',{attrs:{"item":item},on:{"done":_vm.resetTabel}})],1)]}}])}),_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formVisibility),expression:"!formVisibility"}],attrs:{"activePage":_vm.page,"pages":_vm.totalPage,"size":"sm","align":"end"},on:{"update:activePage":function($event){_vm.page=$event},"update:active-page":function($event){_vm.page=$event},"update:pages":function($event){_vm.totalPage=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }