import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async destroy(_ctx, payload) {
        try {
            await axios.delete('/api/pelayanan/' + payload)
        } catch (error) {
            return []
        }
    },
    async update(_ctx, payload) {
        try {
            let data = await axios.patch('/api/pelayanan/' + payload.id, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/pelayanan', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async index(_ctx, payload) {
        const { data } = await axios.get('/api/pelayanan?page=' + payload.page + '&nama_layanan=' + payload.data.nama_layanan)
        return data
    }
}
