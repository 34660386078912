<template>
  <div>
    <CRow sm="12">
      <CCol sm="2" lg="3">
        <total-data title='TOTAL PEGAWAI' url='api/pegawai/total-pegawai' color='primary'/>
      </CCol>
      <CCol sm="2" lg="3">
        <total-data title='PRESENSI MASUK' url='api/pegawai/total-presensi-masuk' color='success'/>
      </CCol>
      <CCol sm="2" lg="3">
        <total-data title='PRESENSI KELUAR' url='api/pegawai/total-presensi-keluar' color='info'/>
      </CCol>
      <CCol sm="2" lg="3">
        <total-data title='TERLAMBAT' url='api/pegawai/total-terlambat' color='warning'/>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="2" lg="3">
        <total-data title='DINAS' url='api/pegawai/total-terlambat' color='info'/>
      </CCol>
      <CCol sm="2" lg="3">
        <total-data title='CUTI' url='api/pegawai/total-cuti' color='success'/>
      </CCol>
      <CCol sm="2" lg="3">
        <total-data title='TANPA KETERANGAN' url='api/pegawai/total-tanpa-keterangan' color='danger'/>
      </CCol>
    </CRow>
    
    <CCard>
      <CCardBody>
        <CRow>
          <!-- <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">GRAFIK KEHADIRAN HARIAN PEGAWAI</h4>
            <div class="small text-muted">Periode 01 s/d 31 MEI 2021</div>
          </CCol> -->
          <!-- <CCol sm="7" class="d-none d-md-block">
            <CButton color="primary" class="float-right">
              <CIcon name="cil-cloud-download"/>
            </CButton>
            <CButtonGroup class="float-right mr-3">
              <CButton
                color="outline-secondary"
                v-for="(value, key) in ['Day', 'Month', 'Year']"
                :key="key"
                class="mx-0"
                :pressed="value === selected ? true : false"
                @click="selected = value"
              >
                {{value}}
              </CButton>
            </CButtonGroup>
          </CCol> -->
        </CRow>
        <CRow>
          <CCol sm="6">
            <CCol sm="6">
              <h4 id="traffic" class="card-title mb-0">GRAFIK RATA RATA KEHADIRAN HARIAN PEGAWAI</h4>
              <div class="small text-muted">Periode 01 s/d 31 MEI 2021</div>
            </CCol>
            <ChartRataRata style="height:300px;margin-top:40px;"/>
          </CCol>
          <CCol sm="6">
            <CCol sm="6">
              <h4 id="traffic" class="card-title mb-0">GRAFIK KEHADIRAN HARIAN PEGAWAI</h4>
              <div class="small text-muted">Periode 01 s/d 31 MEI 2021</div>
            </CCol>
            <MainChartExample style="height:300px;margin-top:40px;"/>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <!-- <CRow class="text-center">
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Total Presensi Masuk</div>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              style="color: #064420"
              :value="100"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Total Presensi Keluar</div>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              :value="100"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Terlambat</div>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="#fb9300"
              :value="100"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Dinas</div>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="danger"
              :value="100"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Cuti</div>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="#f7fd04"
              :value="100"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Tanpa Keterangan</div>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="danger"
              :value="100"
            />
          </CCol>
        </CRow> -->
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import MainChartExample from './charts/MainChartExample'
import ChartRataRata from './charts/ChartRataRata'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import WidgetsBrand from './widgets/WidgetsBrand'
import TotalData from '../components/TotalData.vue' 

export default {
  name: 'Dashboard',
  components: {
    MainChartExample,
    ChartRataRata,
    WidgetsDropdown,
    WidgetsBrand,
    TotalData,
  },
  data () {
    return {
      selected: 'Month',
      tableItems: [
        {
          avatar: { url: 'img/avatars/1.jpg', status: 'success' },
          user: { name: 'Yiorgos Avraamu', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'USA', flag: 'cif-us' },
          usage: { value: 50, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Mastercard', icon: 'cib-cc-mastercard' },
          activity: '10 sec ago'
        },
        {
          avatar: { url: 'img/avatars/2.jpg', status: 'danger' },
          user: { name: 'Avram Tarasios', new: false, registered: 'Jan 1, 2015' },
          country: { name: 'Brazil', flag: 'cif-br' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Visa', icon: 'cib-cc-visa' },
          activity: '5 minutes ago'
        },
        {
          avatar: { url: 'img/avatars/3.jpg', status: 'warning' },
          user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'India', flag: 'cif-in' },
          usage: { value: 74, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Stripe', icon: 'cib-stripe' },
          activity: '1 hour ago'
        },
        {
          avatar: { url: 'img/avatars/4.jpg', status: '' },
          user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'France', flag: 'cif-fr' },
          usage: { value: 98, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'PayPal', icon: 'cib-paypal' },
          activity: 'Last month'
        },
        {
          avatar: { url: 'img/avatars/5.jpg', status: 'success' },
          user: { name: 'Agapetus Tadeáš', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Spain', flag: 'cif-es' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Google Wallet', icon: 'cib-google-pay' },
          activity: 'Last week'
        },
        {
          avatar: { url: 'img/avatars/6.jpg', status: 'danger' },
          user: { name: 'Friderik Dávid', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Poland', flag: 'cif-pl' },
          usage: { value: 43, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Amex', icon: 'cib-cc-amex' },
          activity: 'Last week'
        }
      ],
      tableFields: [
        { key: 'avatar', label: '', _classes: 'text-center' },
        { key: 'user' },
        { key: 'country', _classes: 'text-center' },
        { key: 'usage' },
        { key: 'payment', label: 'Payment method', _classes: 'text-center' },
        { key: 'activity' },
      ]
    }
  },
  methods: {
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
