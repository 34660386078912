
import axios from 'axios';
// actions
export const actions = {

    async autocomplete() {
        try {
            const { data } = await axios.get('/api/master-pejabat-penetap/autocomplete')
            return data
        } catch (error) {
            return []
        }
    },
}
