<template>
  <CCard :color="color" textColor="white">
    <CCardHeader>
      {{ title }}
    </CCardHeader>
    <CCardBody>
      {{ total }}
      <CElementCover 
        :boundaries="[{ sides: ['top', 'left'], query: '.card-body' }]"
        :opacity="0.8"
        v-if="loading"
      >
        <h1 class="d-inline">Loading... </h1><CSpinner size="5xl" color="success"/>
      </CElementCover>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      total: 0,
      days: [],
      loading: false,
    }
  },
  props: ['title', 'url', 'color'],
  mounted() {
    this.loadData();
    
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        let data = await axios.get(this.url);

        if (data) {
          this.total = data.data
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>