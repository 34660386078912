<template>
  <div>
    <CCard>
      <CCardBody>
        <CForm @submit.stop.prevent="handleSubmit(submit)">
          <CRow>
            <CCol sm="6">
              <CInput
                label="Tanggal Awal Cuti"
                addLabelClasses="font-weight-bold"
                plaintext
                v-model="form.start_date"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Tanggal Akhir Cuti"
                addLabelClasses="font-weight-bold"
                :plaintext=true
                v-model="form.end_date"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Jenis Cuti"
                addLabelClasses="font-weight-bold"
                plaintext
                v-model="leave_type"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Alasan Cuti"
                addLabelClasses="font-weight-bold"
                plaintext
                v-model="form.leave_reason"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Alamat Cuti"
                addLabelClasses="font-weight-bold"
                plaintext
                v-model="form.address_leave"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="No Telpon"
                addLabelClasses="font-weight-bold"
                plaintext
                v-model="form.phone_leave"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CSelect
                label="Approval"
                addLabelClasses="font-weight-bold"
                v-model="form.supervisor_approval"
                :value.sync="selectedOption"
                :options="[{value: '', label: '--Pilih--'}, {value: '2', label: 'Disetujui'}, {value: '3', label: 'Ditolak'}]"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CTextarea
                label="Catatan"
                addLabelClasses="font-weight-bold"
                v-model="form.supervisor_response"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="text-left">
              <CSpinner color="success" size="sm" v-if="loading" />
              <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  props: ['item'],
  data() {
    return {
      form: {},
      leave_type: null,
      selectedOption: null,
      loading: false
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const result = await this.$store.dispatch("general/findById", {url: '/api/leave/detail', id: this.item.id_leave})
      
      if(result) {
        this.form = result
        this.leave_type = this.item.jenis_cuti
      }
    },
    handleSubmit() {
      const vm = this;
      try {
        this.loading = true;

        let result = this.$store.dispatch("general/update", {
              url: 'api/leave',
              data: {'supervisor_response': this.form.supervisor_response, 'supervisor_approval': this.selectedOption },
              id: vm.item.id_leave,
            })
        
        if (result) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }

      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>