<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('responsive/set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img :src="require('@/assets/images/16173546914329.png')" class="c-sidebar-brand-full c-icon c-icon-custom-size" />

      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 556 134"
        role="img"
        class="c-sidebar-brand-full c-icon c-icon-custom-size"
        height="35"
      >
        <g
          transform="translate(0.000000,35.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        />
      </svg>-->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('responsive/set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    show() {
      return this.$store.state.responsive.sidebarShow;
    },
    minimize() {
      return this.$store.state.responsive.sidebarMinimize;
    },
  },
};
</script>
