import Vue from 'vue'
import Router from 'vue-router'

import {
  scrollBehavior,
  beforeEach
} from '@/router/router'
// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

// Jabatab Umum
const AbkUmum = () => import('@/views/jabatan_umum/abk/Index')
const InformasiUmum = () => import('@/views/jabatan_umum/informasi/Index')

// Jabatan Functional
const AbkFunctional = () => import('@/views/jabatan_functional/abk/Index')
const InformasiFunctional = () => import('@/views/jabatan_functional/informasi/Index')

// Jabatan Stuktural
const InformasiStuktural = () => import('@/views/jabatan_stuktural/informasi/Index')

// Uji Kompetensi
const UjiKompetensi = () => import('@/views/uji_kompetensi/Index')

// Monitoring Jabatan Fungsional
const MJabatanFungsional = () => import('@/views/monitoring/jabatan_fungsional/Index')

// Monitoring Jabatan Umum
const MJabatanUmum = () => import('@/views/monitoring/jabatan_umum/Index')

// Jenis Layanan
const JenisLayanan = () => import('@/views/layanan/jenis/Index')

// Jenis Layanan
const PersyaratanPelayanan = () => import('@/views/layanan/persyaratan_pelayanan/Index.vue')

// Jenis Layanan
const UsulanKenaikanPangkat = () => import('@/views/layanan/usulan_kenaikan_pangkat/Index.vue')

// Master
const OfficeTime = () => import('@/views/master/office_time/Index')
const MasterLeave = () => import('@/views/master/leave/Index')

// absen
const Attendance = () => import('@/views/attendance/Index')

// cuti
const Leave = () => import('@/views/leave/Index')

Vue.use(Router)

let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: scrollBehavior,
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/monitoring-jabatan-fungsional',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'layanan',
          redirect: '/layanan/jenis-layanan',
          name: 'Layanan',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'jenis-layanan',
              name: 'Jenis Layanan',
              component: JenisLayanan
            },
            {
              path: 'persyaratan-pelayanan',
              name: 'Persyaratan Pelayanan',
              component: PersyaratanPelayanan
            },
            {
              path: 'usulan-kenaikan-pangkat',
              name: 'Usulan Kenaikan Pangkat',
              component: UsulanKenaikanPangkat
            },
          ]
        },
        {
          path: 'admin',
          redirect: '/admin/abk-jabatan-umum',
          name: 'Admin',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'abk-jabatan-umum',
              name: 'ABK Jabatan Umum',
              component: AbkUmum
            },
            {
              path: 'informasi-jabatan-umum',
              name: 'Informasi Jabtan Umum',
              component: InformasiUmum
            },
            {
              path: 'abk-jabatan-functional',
              name: 'ABK Jabatan Functional',
              component: AbkFunctional
            },
            {
              path: 'informasi-jabatan-functional',
              name: 'Informasi Jabatan Functional',
              component: InformasiFunctional
            },
            {
              path: 'informasi-jabatan-stuktural',
              name: 'Informasi Jabatan Struktural',
              component: InformasiStuktural
            },
          ]
        },
        {
          path: 'master',
          // redirect: '/master/abk-jabatan-umum',
          name: 'Master',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'office-time',
              name: 'Master Office Time',
              component: OfficeTime
            },
            {
              path: 'leave',
              name: 'Master Leave',
              component: MasterLeave
            },
            
          ]
        },

        {
          path: 'uji-kompetensi',
          name: 'Uji Kompetensi',
          component: UjiKompetensi
        },
        {
          path: 'monitoring-jabatan-fungsional',
          name: 'Monitoring Jabatan Fungsional',
          component: MJabatanFungsional
        },
        {
          path: 'monitoring-jabatan-umum',
          name: 'Monitoring Jabatan Umum',
          component: MJabatanUmum
        },
        {
          path: 'attendance',
          name: 'Absen',
          component: Attendance
        },
        {
          path: 'leave',
          name: 'Cuti',
          component: Leave
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '*',
      redirect: '/pages/404',
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}


router.beforeEach(beforeEach)

export default router